<template>
  <article>
    <h1>Privacy- en cookieverklaring</h1>
    <div class="content">
      <div>
        <p><em>Wij respecteren de privacy van bezoekers
        van de website en dragen er zorg voor dat de persoonlijke informatie die u ons verschaft
        vertrouwelijk wordt behandeld. Verwerking van de persoonsgegevens gebeurt op een wijze, die in
        overeenstemming is met de eisen die de Wet Bescherming Persoonsgegevens stelt.</em></p>
        <p>Google Analytics</p>
        <p>Wij maken gebruik van Google Analytics om bij te houden hoe gebruikers de Website gebruiken en
        hoe effectief onze Adwords-advertenties bij Google zoekresultaatpagina’s zijn. De aldus
        verkregen informatie wordt, met inbegrip van het adres van uw computer (IP-adres), overgebracht
        naar en door Google opgeslagen op servers in de Verenigde Staten. Lees het <a
          href="https://www.google.com/intl/nl/policies/privacy/">privacybeleid van Google</a>
          voor meer informatie, alsook het specifieke <a
            href="https://support.google.com/analytics/answer/6004245?hl=nl">privacybeleid van
          Google Analytics</a>.</p>
        <p>Google gebruikt deze informatie om bij te houden hoe onze website gebruikt wordt, om rapporten
        over de Website aan ons te kunnen verstrekken en om haar adverteerders informatie over de
        effectiviteit van hun campagnes te kunnen bieden. Google kan deze informatie aan derden
        verschaffen indien Google hiertoe wettelijk wordt verplicht, of voor zover deze derden de
        informatie namens Google verwerken. Wij hebben hier geen invloed op. Wij hebben Google niet
        toegestaan de verkregen analytics informatie te gebruiken voor andere Google diensten.</p>
        <p>Facebook en Twitter</p>
        <p>Op deze site zijn knoppen opgenomen om pagina’s te kunnen promoten of delen op sociale netwerken
        Facebook en Twitter. Deze knoppen worden gerealiseerd door code die wordt aangeleverd door
        Facebook en Twitter zelf. Deze code plaatst onder meer een cookie (zie boven).</p>
        <p>Leest u de privacyverklaring <a href="http://www.facebook.com/policy.php">van Facebook</a> en <a
          href="https://twitter.com/privacy">van Twitter </a>(welke regelmatig kunnen wijzigen) om te
          zien wat zij met uw persoonsgegevens doen die zij met deze code verwerken.</p>
        <p>Gebruik van cookies</p>
        <p>Wij maken bij het aanbieden van elektronische diensten gebruik van cookies. Een cookie is een
        eenvoudig klein bestandje dat met pagina’s van deze website wordt meegestuurd en door uw browser
        op de harde schijf van uw computer wordt opgeslagen. Wij gebruiken cookies om uw instellingen en
        voorkeuren te onthouden. U kunt deze cookies uitzetten via uw browser, zie bijvoorbeeld deze <a
          href="http://www.consumentenbond.nl/test/elektronica-communicatie/veilig-online/privacy-op-internet/extra/cookies-verwijderen/">toelichting
          door de Consumentenbond</a> voor uitleg.</p>
        <p>Via onze website worden cookies geplaatst van het Amerikaanse bedrijf Google, als deel van de
        “Analytics”-dienst. Wij gebruiken deze dienst om bij te houden en rapportages te krijgen over
        hoe bezoekers de website gebruiken. Google kan deze informatie aan derden verschaffen indien
        Google hiertoe wettelijk wordt verplicht, of voor zover derden de informatie namens Google
        verwerken. Wij hebben hier geen invloed op.</p>
      </div>
    </div>

  </article>
</template>

<script>
export default {
  name: 'CookieStatement',
};
</script>

<style lang="scss" scoped>
@import "~@/styles/variables";

article {
    margin: 70px auto 0;
    max-width: 1112px;
    overflow: auto;
    text-align: left;

    h1 {
        margin: 20px 0;
    }
}
</style>
